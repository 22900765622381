import React from "react"
import { Link } from 'gatsby'
import Img from "gatsby-image"
import styled from 'styled-components'

import Layout from "../../components/layout-v2"
import SEO from "../../components/seo"
import ContactUs from "../../components/contact-us"

const TeamPage = ({ data: { teamMember, pageContent } }) => {

    const team = teamMember.edges
    const content = pageContent.edges

    return(
        <Layout>
            {content.map(page => ( 
                <SEO 
                title={page.node.SEOmeta.metaTitle} 
                description={page.node.SEOmeta.metaDescription}
                keywords={page.node.SEOmeta.metaKeywords}
                ogTitle={page.node.SEOmeta.ogTitle} 
                ogDescription={page.node.SEOmeta.ogDescription}
                ogImage={page.node.SEOmeta.ogImage.localFile.childImageSharp.fluid}
                twitterTitle={page.node.SEOmeta.twitterTitle} 
                twitterDescription={page.node.SEOmeta.twitterDescription}
                twitterImage={page.node.SEOmeta.twitterImage.localFile.childImageSharp.fluid}
                />
            ))}
            <div style={{padding: '0 20px', }}>
                <TitleSection>
                    <h1>Meet the Team</h1>
                    <p>Your squad of digital marketers, designers, creators, and analysts</p>
                </TitleSection>
                <MainLoop>
                    {team.map(post => (
                        <article
                        itemScope
                        itemType="http://schema.org/Article"
                        >
                            <Link to={`/about/meet-the-team/${post.node.slug}`} itemProp="url" >
                                <div class="team-image">
                                    <Img fluid={post.node.featuredImage.node.localFile.childImageSharp.fluid} alt={post.node.title} />
                                </div>
                                <div class="team-hover">
                                    <h2 dangerouslySetInnerHTML={{ __html: post.node.title }} itemProp="headline"/>
                                    <h3>{post.node.TeamMemberJobTitle.tmJobTitle}</h3>
                                </div>
                            </Link>
                        </article>
                    ))}
                </MainLoop>
                {/* <BottomContent>
                    <div class="full-row">
                        <h2>Join Our Team?</h2>
                        <p>This is where you come in. You might just be a good fit for our team!</p>
                    </div>
                    <div class="half-row">
                        <h3>Front-End Developer</h3>
                        <p>Join us in our vision to be the recognized go-to regional interactive agency for handling complex and challenging web and mobile projects.</p>
                        <Link to={"#"}>Apply Now</Link>
                    </div>
                    <div class="half-row">
                        <h3>Project Manager</h3>
                        <p>We are always looking for great new talent. If you'd like to stay on our radar, please fill out the application.</p>
                        <Link to={"#"}>Apply Now</Link>
                    </div>
                </BottomContent> */}
                <ContactUs />
            </div>
        </Layout>
    );

}

const TitleSection = styled.header`
    max-width: 1320px;
    width: 100%;
    padding: 80px 70px;
    margin: 0 auto; 
    margin-top: 20px;
    margin-bottom: 60px;
    border: 10px solid #db4140;
    h1 {
        font-size: 100px;
        margin-top: 0;
        margin-bottom: 0;
    }
    p {
        font-family: "Work Sans";
        font-size: 30px;
        font-weight: 400;
        color: #777c7c;
        margin-bottom: 0;
    }
    @media(max-width:1000px) {
      max-width: 700px;
      padding: 45px;
      h1 {
        font-size: 60px;
      }
      p {
        font-size: 24px;
      }
    }
    @media(max-width: 767px) {
      max-width: 500px;
      padding: 20px;
      border: 5px solid #db4140;
      margin-bottom: 20px;
      h1 {
        font-size: 44px;
      }
      p {
        font-size: 18px;
        line-height: 1.4;
      }
    }
`

const MainLoop = styled.section`
    max-width: 1320px;
    width: 100%;
    margin: 70px auto;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    article {
      width: calc(33.33% - 27px);
      height: 350px;
      margin-bottom: 40px;
      margin-right: 40px;
      position: relative;
        &:nth-child(3n) {
          margin-right: 0px;
        }
        a {
            text-decoration: none;
            color: #2b2520;
        }
        div.team-image {
            position: absolute;
            height: 100%;
            width: 100%;
            .gatsby-image-wrapper {
                height: 100%;
            }
        }
        div.team-hover {
            position: absolute;
            left: 15px;
            width: calc(100% - 30px);
            background-color: #fff;
            z-index: 10;
            padding: 15px;
            transition-duration: .5s;
            opacity: 0;
            bottom: 0;
        }
        h2 {
            font-family: "Nobel",sans-serif;
            font-size: 30px;
            line-height: 42px;
            font-weight: 700;
            color: #2b2520;
            margin-top: 0px;
            margin-bottom: 0px;
            transition-duration: .3s;
        }
        h3 {
            font-family: "Work Sans";
            font-size: 18px;
            line-height: 20px;
            font-weight: 400;
            color: #777c7c;
            margin-top: 0;
            margin-bottom: 0px;
        }
        &:hover {
            div.team-hover {
                opacity: 1;
                bottom: 15px;
            }
        }
    }
    @media(max-width:1000px) {
      max-width: 700px;
      article {
        width: calc(50% - 20px);
        &:nth-child(3n) {
          margin-right: 40px;
        }
        &:nth-child(2n) {
          margin-right: 0px;
        }
      }
    }
    @media(max-width: 767px) {
      max-width: 500px;
      margin-top: 20px;
      article {
        width: 100%;
        margin-right: 0 !important;
        div.team-hover {
            opacity: 1;
            bottom: 15px;
        }
        h2 {
          font-size: 24px;
          line-height: 1.2;
        }
      }
    }
`

const BottomContent = styled.section`
    max-width: 1190px;
    width: 100%;
    margin: 50px auto;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .full-row {
        width: 100%;
        text-align: center;
        h2 {
            font-family: "Nobel",sans-serif;
            font-size: 48px;
            line-height: 1.2;
            font-weight: 700;
            color: #2b2520;
            margin-top: 0px;
            margin-bottom: 20px;
        }
        p {
            font-family: "Work Sans";
            font-size: 24px;
            line-height: 1.2;
            font-weight: 400;
            color: #777c7c;
            margin: 0 auto;
            max-width: 490px;
        }
    }
    .half-row {
        width: 50%;
        padding-right: 60px;
        padding-top: 60px;
        h3 {
            font-family: "Nobel",sans-serif;
            font-size: 36px;
            line-height: 1.2;
            font-weight: 700;
            color: #2b2520;
            margin-top: 0px;
            margin-bottom: 20px;
        }
        p {
            font-family: "Work Sans";
            font-size: 18px;
            font-weight: 400;
            line-height: 38px;
            color: #777c7c;
            margin-bottom: 0;
        }
        a {
            font-family: "Work Sans";
            font-size: 20px;
            line-height: 1;
            font-weight: 700;
            color: #fff !important;
            text-decoration: none;
            background-color: #db4140;
            display: inline-block;
            padding: 20px 50px;
            margin: 20px auto;
        }
    }
    @media(max-width:1000px) {
        max-width: 700px;
        .half-row {
            width: 100%;
            padding-right: 0;
            padding-top: 40px;
            text-align: center;
        }
      }
`

export default TeamPage

export const pageQuery = graphql`
    query {
        teamMember: allWpWlTeamMember(sort: {fields: date, order: DESC}) {
            edges {
                node {
                    title
                    slug
                    featuredImage {
                        node {
                          localFile {
                            childImageSharp {
                              fluid(maxWidth: 750, quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp
                              }
                            }
                          }
                        }
                      }
                    TeamMemberJobTitle {
                        tmJobTitle
                    }
                }
            }
        }
        pageContent: allWpCustomPage(filter: {databaseId: {eq: 1435}}) {
            edges {
              node {
                SEOmeta {
                    metaTitle
                    metaDescription
                    metaKeywords
                    ogTitle
                    ogDescription
                    ogImage {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 1320, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                    twitterTitle
                    twitterDescription
                    twitterImage {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 1320, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                  }
              }
            }
          }
    }
`